<script lang="ts" setup>
import type { Models } from '~/types/models'

const props = defineProps({
    user: {
        type: Object as PropType<Models.User>,
        required: true,
    },
    badge: {
        type: Boolean,
    },
    notificationsCount: {
        type: Number,
    },
})

const { $assets } = useNuxtApp()

const fallback = ref(null) as Ref<null | string>
</script>

<template>
    <div class="wrapper">
        <div v-if="user.image && user.image.url" class="image">
            <img
                :src="fallback || user.image.url"
                :alt="user.name"
                :title="user.name"
                width="20px"
                :class="{ 'object-contain p-1': fallback, 'object-cover': !fallback }"
                height="20px"
                @error="fallback = $assets.gray.userHeader"
            />
        </div>
        <div v-else class="image">
            <div class="image__painted-avatar" alt="Imagen de usuario">
                {{ user.name.split('')[0] }}
            </div>
        </div>
        <div v-if="user.verified && badge" class="wrapper__verified">
            <img :src="$assets.primary.verified" alt="verificado" width="12" height="12" loading="lazy" />
        </div>
        <div v-if="notificationsCount" class="wrapper__notifications"></div>
    </div>
</template>

<style lang="postcss" scoped>
.wrapper {
    @apply relative;
    .image {
        @apply h-full w-full overflow-hidden rounded-full bg-white;
        img {
            @apply h-full w-full;
        }
        &__painted-avatar {
            @apply flex h-full w-full items-center justify-center rounded-full bg-[#0086E8] text-[1em] text-white;
        }
    }
    &__verified {
        @apply absolute -right-0.5 -top-0.5 h-3 w-3;
    }
    &__notifications {
        @apply absolute -right-0 -top-0 z-[500] block h-3.5 w-3.5 rounded-full bg-[#33CCFF] text-center text-xs text-white shadow-xl md:hidden;
    }
}
</style>
